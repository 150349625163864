<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">COMPANY RECEIVABLE (LOAN)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12
                v-if="position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'|| position === 'AR/AP'">
          <v-form ref="form2" class="multi-col-validation">
            <v-select v-model="status"
                      class="mx-2"
                      dense
                      outlined
                      label="Status"
                      :items="['Unpaid','Paid']"
                      :rules="rules.combobox_rule"
                      @change="selected_status"
            ></v-select>
            <v-checkbox v-model="is_search" label="Search?"
                        @change="selected_status(status)"></v-checkbox>
            <v-autocomplete
              v-if="is_search"
              dense
              outlined
              v-model="search"
              :loading="isLoading"
              @keyup.enter="searching($event.target.value)"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="id"
              label="Search"
              class="mx-2"
              placeholder="Press Enter to Search "
              :prepend-icon="icons.mdiAccountSearchOutline"
            ></v-autocomplete>
          </v-form>
        </v-flex>
        <v-layout col>
          <v-flex xs12 md6 class="mx-2">
            <v-text-field outlined v-model="total_amount" label="Total Amount"
                          readonly></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="mx-2">
            <v-text-field outlined v-model="total_balance" label="Total Balance"
                          readonly></v-text-field>
          </v-flex>
        </v-layout>
        <v-flex xs12 md12 class="mx-2">
          LEGENDS -
          (SOA-
          <v-icon
            class="mr-2"
            color="success"
          >
            {{icons.mdiPrinter}}
          </v-icon>
          )
          <div
            v-if="position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'">
            (BOD CHARGES-
            <v-icon
              class="mr-2"
              color="warning"
            >
              {{icons.mdiFolderSwap}}
            </v-icon>
            ),(TRANSFER-
            <v-icon
              class="mr-2"
              color="info"
            >
              {{icons.mdiTransferRight}}
            </v-icon>
            ),
          </div>
        </v-flex>
        <v-flex xs12 md12 class="mx-2">
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'?tabs:tabs1"
              :key="tab.title"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.employee!=null?item.employee.last_name+', '+item.employee.first_name
                      +' '+item.employee.middle_name:item.name }}
                    </td>
                    <td>
                      {{ item.purpose }}
                    </td>
                    <td>
                      {{ item.date }}
                    </td>
                    <td>
                      {{formatPrice(item.amount)}}
                    </td>
                    <td>
                      {{ formatPrice(parseFloat(item.total)-parseFloat(item.amount))}}
                    </td>
                    <td>
                      {{formatPrice(item.total)}}
                    </td>
                    <td>
                      {{ balance_calculation(item)}}
                    </td>
                    <td>
                      {{ formatPrice(item.period_amount) }}
                    </td>
                    <td>
                      {{ item.transaction_month.month_of}}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'&& (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="warning"
                        @click="bod_charge(item)"
                      >
                        {{icons.mdiFolderSwap}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'"
                        class="mr-2"
                        color="info"
                        @click="transfer_data(item)"
                      >
                        {{icons.mdiTransferRight}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items2"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.employee!=null?item.employee.last_name+', '+item.employee.first_name
                      +' '+item.employee.middle_name:item.name }}
                    </td>
                    <td>
                      {{ item.purpose }}
                    </td>
                    <td>
                      {{ item.date }}
                    </td>
                    <td>
                      {{formatPrice(item.amount)}}
                    </td>
                    <td>
                      {{ formatPrice(parseFloat(item.total)-parseFloat(item.amount))}}
                    </td>
                    <td>
                      {{formatPrice(item.total)}}
                    </td>
                    <td>
                      {{ balance_calculation(item)}}
                    </td>
                    <td>
                      {{ formatPrice(item.period_amount) }}
                    </td>
                    <td>
                      {{ item.transaction_month.month_of}}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'&& (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="warning"
                        @click="bod_charge(item)"
                      >
                        {{icons.mdiFolderSwap}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'"
                        class="mr-2"
                        color="info"
                        @click="transfer_data(item)"
                      >
                        {{icons.mdiTransferRight}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
    <v-dialog v-model="Transfer" persistent max-width="50%" :key="key">
      <v-card>
        <v-card-text>
          <v-form ref="form3" class="multi-col-validation">
            <v-toolbar dense dark color="primary" class="mt-5 mb-5">
              <v-toolbar-title><h4 class="font-weight-light">TRANSFER LOAN</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-layout row wrap class="align-center mx-2">
              <v-flex xs12 md12>
                <v-select
                  v-model="selection"
                  class="mx-2"
                  dense
                  outlined
                  label="Selection"
                  :items="['Employee','Non-Employee']"
                  @change="selected"
                ></v-select>
              </v-flex>
              <v-flex xs12 md12 v-if="selection==='Employee'">
                <v-select
                  v-model="category_id"
                  class="mx-2"
                  dense
                  outlined
                  label="Category"
                  :items="category_items"
                  item-text="category"
                  item-value="id"
                  @change="selected_category"
                ></v-select>
              </v-flex>
              <v-flex xs12 md12 v-if="selection==='Employee'">
                <v-autocomplete
                  dense
                  outlined
                  v-model="searchh"
                  :items="search_itemss"
                  :loading="isLoading"
                  @keyup.enter="searchingg($event.target.value)"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="id"
                  label="Search"
                  placeholder="Press Enter to Search "
                  :prepend-icon="icons.mdiAccountSearchOutline"
                  @change="get_search_items_infoo"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
            <v-toolbar dense dark color="primary" class="mt-5 mb-5">
              <v-toolbar-title><h4 class="font-weight-light">BORROWER'S INFORMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-layout row wrap class="align-center mx-2" v-if="selection==='Employee'">
              <v-flex xs12 md6>
                <v-text-field
                  class="mx-2"
                  v-model="namee"
                  label="Name"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="align-center mx-2" v-else>
              <v-flex xs12 md6>
                <v-text-field
                  class="mx-2"
                  v-model="namee"
                  label="Name"
                  dense
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
          <v-col cols="12" v-show="alert_customize">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message_customize}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
        </v-card-text>
      </v-card>
      <v-btn color="primary" @click="transfer"> Save Changes</v-btn>
      <v-btn color="error" @click="Transfer = false"> Close</v-btn>
    </v-dialog>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiGoogleCirclesCommunities,
    mdiPrinter, mdiTransferRight, mdiFolderSwap,
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Name', value: 'tin', sortable: false},
        {text: 'Purpose', value: 'tin', sortable: false},
        {text: 'Date of Loan', value: 'tin', sortable: false},
        {text: 'Loan Amount', value: 'tin', sortable: false},
        {text: 'Interest', value: 'tin', sortable: false},
        {text: 'Total', value: 'tin', sortable: false},
        {text: 'Loan Balance', value: 'tin', sortable: false},
        {text: 'Period Amount', value: 'tin', sortable: false},
        {text: 'Month Of', value: 'tin', sortable: false},
        {text: 'Action', value: 'tin', sortable: false},
      ],
      data_items: [],
      data_items2: [],
      tab: '',
      namee: '',
      tabs: [
        {title: 'EMPLOYEE', icon: mdiGoogleCirclesCommunities},
        {title: 'OTHERS', icon: mdiAccountOutline},
      ],
      tabs1: [
        {title: 'EMPLOYEE', icon: mdiGoogleCirclesCommunities},
      ],
      is_search: false,
      status: '',
      search: '',
      selection: '',
      isLoading: false,
      total_amount: '0',
      total_balance: '0',
      id: 0,
      eeeid: 0,
      key: 0,
      Transfer: false,
      alert_message_customize: '',
      category_id: '',
      searchh: '',
      alert_customize: false,
      category_items: [],
      search_itemss: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiPrinter,
          mdiTransferRight,
          mdiFolderSwap,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      if (!(this.position === 'ADMIN' || this.position === 'TREASURY' || this.position === 'ACCOUNTING' || this.position === 'CMD')) {
        this.status = 'Unpaid'
        this.searching(this.employee_id)
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'address', 'contact', 'name', 'department', 'position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('loan_application', ['list_of_loan', 'change_loan_to_bod_charge', 'search_list_of_loan', 'transfer_accounting_loan']),
      ...mapActions('request_particulars', ['initialize_new_particulars',]),
      ...mapActions('employee', ['search_active_employee',]),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      balance_calculation(item) {
        var total = item.total
        var payment = 0
        item.payment.forEach(function (item) {
          payment += parseFloat(item.amount)
        });
        var balance = (total - payment)

        return this.formatPrice(balance)
      },
      selected_category() {
        this.namee = ''
        this.search_itemss = []
        this.searchh = ''
        this.eeeid = 0
      },
      searching(value) {
        this.search = value
        this.data_items = []
        this.data_items2 = []
        this.total_amount = '0'
        this.total_balance = '0'
        this.search_list_of_loan({
          search_word: value,
          status: this.status
        })
          .then(response => {
            this.total_amount = response.data[0].total_amount
            this.total_balance = response.data[0].total_balance
            this.data_items = response.data[0].employee
            this.data_items2 = response.data[0].others
            this.isLoading = false
          })
      },
      searchingg(value) {
        this.namee = ''
        this.eeeid = 0

        this.isLoading = true
        this.search_active_employee({
          search_word: value,
          category_id: this.category_id,
        })
          .then(response => {
            this.search_itemss = response.data
            this.isLoading = false
          })
      },
      get_search_items_infoo(value) {
        if (this.search_itemss.length > 0) {
          var index = this.search_itemss.map(function (x) {
            return x.id;
          }).indexOf(value)
          if (index != -1) {
            this.eeeid = this.search_itemss[index].id
            this.namee = this.search_itemss[index].name
          } else {
            this.namee = ''
            this.eeeid = 0
          }
        } else {
          this.namee = ''
          this.eeeid = 0
        }
      },
      selected_status(value) {
        this.data_items = []
        this.data_items2 = []
        this.total_amount = '0'
        this.total_balance = '0'
        if (!this.is_search) {
          this.list_of_loan({
            status: value
          })
            .then(response => {
              this.total_amount = response.data[0].total_amount
              this.total_balance = response.data[0].total_balance
              this.data_items = response.data[0].employee
              this.data_items2 = response.data[0].others
              this.isLoading = false
            })
        } else {
          this.searching(this.search)
        }
      },
      bod_charge(item) {
        if (confirm("PROCEED?")) {
          this.change_loan_to_bod_charge({
            id: item.id
          })
            .then(() => {
              this.selected_status(this.status)
            })
        }
      },
      transfer_data(item) {
        if (confirm("PROCEED?")) {
          this.id = item.id
          this.Transfer = true
          this.key++
          this.initialize_new_particulars()
            .then(response => {
              this.category_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      transfer() {
        this.alert_customize = false
        if (this.$refs.form3.validate()) {
          const data = new FormData()
          data.append('id', this.id);
          data.append('name', this.namee);
          data.append('employee_id', this.eeeid);
          this.transfer_accounting_loan(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.Transfer = false
              this.selected_status(this.status)
            })
            .catch(error => {
              this.alert_customize = true
              this.alert_customize = error
            })
        } else {
          this.alert_customize = true
          this.alert_customize = 'Please Fill up the field/s'
        }
      },

      selected() {
        this.eeeid = 0
      },
      print_soa(value) {
        var imgData = this.company_logo
        var payments_array = []
        var widths = [100, 60, 80, 100, 60]
        var payment = 0
        payments_array.push([
          {text: 'Month Of', alignment: 'left', style: 'label',},
          {text: 'Duration', alignment: 'left', style: 'label'},
          {text: 'Date', alignment: 'left', style: 'label'},
          {text: 'Particulars', alignment: 'left', style: 'label'},
          {text: 'Amount', alignment: 'left', style: 'label'},
        ])
        if (value.payment.length > 0) {
          value.payment.forEach(function (item) {
            if (item.deposit_slip != null) {
              payment += parseFloat(item.amount)
              payments_array.push([
                {text: item.transaction_month.month_of, alignment: 'left', style: 'tableExample'},
                {text: item.duration, alignment: 'left', style: 'tableExample'},
                {text: item.deposit_slip.date, alignment: 'left', style: 'tableExample'},
                {text: item.particulars, alignment: 'left', style: 'tableExample'},
                {
                  text: (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left', style: 'tableExample'
                },
              ])
            }
          })
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader'},
                    {
                      text: this.contact,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'LOAN - STATEMENT OF ACCOUNT (SOA)',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'LOAN ID#: ',
                        {
                          text: value.id,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Amount: ',
                        {
                          text: this.formatPrice(value.amount),
                          style: 'main_data', color: 'blue',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'NAME: ',
                        {
                          text: value.employee != null ? value.employee.last_name + ', ' + value.employee.first_name
                            + ' ' + value.employee.middle_name : value.name,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Interest: ',
                        {
                          text: this.formatPrice((parseFloat(value.total) - parseFloat(value.amount))),
                          style: 'main_data', color: 'red',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date of Loan: ',
                        {
                          text: value.date,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total: ',
                        {
                          text: this.formatPrice(value.total),
                          style: 'main_data', color: 'orange',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date Expected Paid: ',
                        {
                          text: value.expected_paid_date,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Payed: ',
                        {
                          text: this.formatPrice(payment),
                          style: 'main_data', color: 'green',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {text: ''},
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Balance: ',
                        {
                          text: this.formatPrice(parseFloat(value.total) - parseFloat(payment)),
                          style: 'main_data', color: 'red',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Purpose: ',
                        {
                          text: value.purpose,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            'PAYMENT',
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.department,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {image: this.damayan, width: 54, height: 40, style: 'logo'},
                {image: this.chapel, width: 54, height: 40, style: 'logo'},
                {image: this.factory, width: 54, height: 40, style: 'logo'},
                {image: this.coop, width: 54, height: 40, style: 'logo'},
                {image: this.printing, width: 54, height: 40, style: 'logo'},
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.saving = false
      },
    }
  }
</script>
